import EmblaCarousel from "embla-carousel";
import ClassNames from "embla-carousel-class-names";
import { autoPlay } from "./autoplay";
import { addDotBtnsAndClickHandlers } from "./navigation";

function init() {
	// console.log("Banner slider.");

	if (document.querySelector(".banner-slider .embla")) {
		bannerSlider();
	}
}

function bannerSlider() {
	const wrap = document.querySelector(".banner-slider .embla");
	const viewPort = wrap.querySelector(".banner-slider .embla__viewport");
	// const prevBtn = wrap.querySelector(".banner-slider .embla__button--prev");
	// const nextBtn = wrap.querySelector(".banner-slider .embla__button--next");
	const dots = wrap.querySelector(".banner-slider .embla__dot_navigation");

	// Add disabled class if slider has 1 slide
	const slides = wrap.querySelectorAll(".banner-slider .slide");
	let status = true;
	if (slides.length === 1) {
		wrap.classList.add("disabled");
		status = false;
	}

	const embla = EmblaCarousel(
		viewPort,
		{
			active: status,
			align: "start",
			loop: true
		},
		[ClassNames()]
	);

	// Previous/next navigation
	// const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
	// 	embla,
	// 	prevBtn,
	// 	nextBtn
	// );

	// const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
	// 	embla,
	// 	prevBtn,
	// 	nextBtn
	// );

	// Dot navigation
	const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
		embla,
		dots
	);

	// Autoplay
	// const auto = autoPlay(embla, viewPort, 5000);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

export default { init };
