function init() {
	// console.log("Logo");

	const logoBlue =
		themeVars.templateUrl +
		"/assets/images/content/logo-worldclaim-blue.svg";
	const logoWhite =
		themeVars.templateUrl +
		"/assets/images/content/logo-worldclaim-white.svg";

	// Change single post logo from white to blue
	const singlePostMainHeaderLogo = document.querySelectorAll(
		".single-post .site-header .custom-logo"
	);

	if (singlePostMainHeaderLogo) {
		const setBlueLogo = getBlueLogo(singlePostMainHeaderLogo, logoBlue);
	}
}

function getBlueLogo(currentLogo, blueLogo) {
	currentLogo.forEach((logo) => (logo.src = blueLogo));
}

export default { init };
