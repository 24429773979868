import EmblaCarousel from "embla-carousel";
import ClassNames from "embla-carousel-class-names";

function init() {
	// console.log("Logo slider");

	if (document.querySelector(".logo-slider.embla")) {
		logoSlider();
	}
}

function logoSlider() {
	const wrap = document.querySelector(".logo-slider.embla");
	const viewPort = wrap.querySelector(".logo-slider.embla .embla__viewport");
	const embla = EmblaCarousel(
		viewPort,
		{
			align: "start",
			draggable: false,
			loop: true
		},
		[ClassNames()]
	);

	const rafId = {};

	const animate = () => {
		if (!embla || !rafId.current) return;

		const engine = embla.internalEngine();
		engine.location.add(-0.75);
		engine.target.set(engine.location);
		engine.scrollLooper.loop(-1);
		engine.slideLooper.loop();
		engine.translate.to(engine.location);
		rafId.current = requestAnimationFrame(animate);
	};

	const startAutoScroll = () => {
		rafId.current = requestAnimationFrame(animate);
	};

	const stopAutoScroll = () => {
		rafId.current = cancelAnimationFrame(rafId.current) || 0;
	};

	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
	embla.on("pointerDown", stopAutoScroll);
	embla.on("settle", startAutoScroll);
	startAutoScroll();
}

export default { init };
