// Breakpoints
const e10kBreakpoints = {
	lg: 1200,
	md: 992,
	sm: 768,
	xs: 480,
	nav: 1180
};

// Theme variables
// Access varaibles from the theme, add more via the functions.php file
// themeVars.templateUrl - gets the child base URL

// Dev helpers
function e10kDevHelpers() {
	// Highlight all links that have an empty href or that contain "#"
	const allLinkEls = document.querySelectorAll("a");

	allLinkEls.forEach((link) => {
		if (
			"#" === link.getAttribute("href") ||
			"" === link.getAttribute("href")
		) {
			link.classList.add("empty-link");
		}
	});

	// Highlight all lorem ipsum
	const allContentEls = document.querySelectorAll(
		"p, h1, h2, h3, h4, h5, h6, ul, ol, span"
	);

	allContentEls.forEach((el) => {
		console.log(el);
		if (
			el.innerText.toLowerCase().includes("lorem") ||
			el.innerText.toLowerCase().includes("ipsum") ||
			el.innerText.toLowerCase().includes("dolor") ||
			el.innerText.toLowerCase().includes("amet") ||
			el.innerText.toLowerCase().includes("consectetur") ||
			el.innerText.toLowerCase().includes("labore")
		) {
			el.classList.add("lorem-ipsum");
		}
	});
}

// Format phone numbers; populate phone number link based on element text
// Todo: Add support for extensions
function e10kFormatPhoneNumbers() {
	const phoneNumbers = document.querySelectorAll("a.tel, .tel a");

	// Loop through phone numbers
	for (let i = 0; i < phoneNumbers.length; i++) {
		let phoneNumber = "";

		// Remove '1-' prefix
		phoneNumber = phoneNumbers[i].text.replace(/^(1-)/, "");

		// If starts with '+' add 'tel:' prefix; remove non-digit characters like '-'
		if (phoneNumber.startsWith("+")) {
			phoneNumber = "tel:" + phoneNumber.replace(/\D/g, "");

			// Else add 'tel:1' prefix; remove non-digit characters like '-'
		} else {
			phoneNumber = "tel:1" + phoneNumber.replace(/\D/g, "");
		}

		// Set element href attribute value to updated phone number
		phoneNumbers[i].setAttribute("href", phoneNumber);
	}
}

// Use IntersectionObserver to detect if element is in the viewport; add or remove 'is-visible' class based on this
function e10kInViewport(element) {
	const el = document.querySelector(element);
	const observer = new window.IntersectionObserver(
		([entry]) => {
			if (entry.isIntersecting) {
				console.log("Enter");
				el.classList.add("is-visible");
				return;
			} else {
				console.log("Exit");
				el.classList.remove("is-visible");
			}
		},
		{
			root: null,
			threshold: 0.5
		}
	);

	observer.observe(el);
}

// Remove empty <p> tags including those that contain spaces
function e10kRemoveEmptyPTags(els) {
	els.forEach((el) => {
		const str = el.innerHTML;
		const updatedStr = str.replace(/<p[^>]*>(?:\s|&nbsp;)*<\/p>/gi, "");
		el.innerHTML = updatedStr;
	});
}

// Video fade in
function e10kVideoFadeIn(videos) {
	videos.forEach(function (video) {
		if (video) {
			function showVideo() {
				video.classList.add("fade-in");
			}

			if (video.readyState < 3) {
				video.addEventListener("canplaythrough", (e) => {
					showVideo(video);
				});
			} else {
				setTimeout(function () {
					showVideo(video);
				}, 1000);
			}
		}
	});
}

export default {
	e10kBreakpoints,
	e10kDevHelpers,
	e10kFormatPhoneNumbers,
	e10kInViewport,
	e10kRemoveEmptyPTags,
	e10kVideoFadeIn
};
