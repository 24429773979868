import ada from "./modules/ada";
import astra from "./modules/astra";
import faq from "./modules/faq";
import jobs from "./modules/jobs";
import languageSelector from "./modules/language-selector";
import logo from "./modules/logo";
import modalContact from "./modules/modals/contact";
import modalTeamMember from "./modules/modals/team-member";
import modalVideo from "./modules/modals/video";
import sliderBanner from "./modules/sliders/banner";
import sliderGrid from "./modules/sliders/grid";
import sliderLogo from "./modules/sliders/logo";
import sliderTestimonial from "./modules/sliders/testimonial";
import utils from "./modules/utils";

// Utils
// Dev helpers
// utils.e10kDevHelpers();

// Format phone numbers automatically
utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
utils.e10kRemoveEmptyPTags(
	document.querySelectorAll(
		".site-above-footer-wrap .ast-builder-html-element, .site-footer-primary-section-2 .ast-builder-html-element, .site-footer-primary-section-3 .ast-builder-html-element, .site-footer-primary-section-4 .ast-builder-html-element"
	)
);

// Video fade
utils.e10kVideoFadeIn(
	document.querySelectorAll(
		".background-video video, .background-video iframe"
	)
);

// In Viewport
// utils.e10kInViewport();

// ADA - Accessibility scripts, mainly fixes
// ada.init();

// Astra - Theme scripts
astra.init();

// FAQ
faq.init();

// Jobs
jobs.init();

// Language select
languageSelector.init();

// Logo
logo.init();

// Mapbox
// Go to ../partials/blocks/mapbox/block.js

// Modals
modalContact.init();
modalTeamMember.init();
modalVideo.init();

// Sliders
sliderBanner.init();
sliderGrid.init();
sliderLogo.init();
sliderTestimonial.init();
