function init() {
	// console.log("Lanaguage selector.");

	const languageSelectorEl = document.querySelectorAll("#language-selector");
	if (languageSelectorEl.length) {
		const languageSelectorScripts = handle(languageSelectorEl);
	}
}

function handle(selector) {
	const currentOrigin = window.location.origin;
	const currentHref = window.location.href;

	selector.forEach((el) => {
		// Update selected value based on current URL
		if (currentHref.includes("/es/")) {
			el.value = "/es/";
		} else if (currentHref.includes("/fr/")) {
			el.value = "/fr/";
		} else {
			el.value = "/";
		}

		// On change redirect to link
		el.addEventListener("change", (e) => {
			window.location.href = currentOrigin + e.target.value;
		});
	});
}

export default { init };
