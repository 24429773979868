function init() {
	// console.log("Jobs");

	const jobListEl = document.querySelector("#jobs-list");
	if (jobListEl) {
		// Filter
		const filter = locationFilter();

		// Update pagination
		const jobListPagination = jobListPaginationUpdate();
	}
}

function locationFilter() {
	const select = document.querySelector(".location-filter > select");

	select.addEventListener("change", function () {
		console.log("Filter changed.");

		// If All Locations selected, redirect to main jobs page; this prevents issues with pagination
		if (this.value === "") {
			window.location =
				window.location.protocol +
				"//" +
				window.location.host +
				"/about/culture/#jobs-list";
		} else {
			// $.ajax({
			// 	type: "post",
			// 	url: "/wp-admin/admin-ajax.php",
			// 	data: {
			// 		action: "job_location_filter",
			// 		job_location_filter: $(this).val()
			// 	},
			// 	success: function (data) {
			// 		// console.log(data);
			// 		$(".jobs-wrapper").html(data);
			// 	}
			// });

			let formData = new FormData();

			formData.append("action", "job_location_filter");
			formData.append("job_location_filter", this.value);

			fetch("/wp-admin/admin-ajax.php", {
				method: "post",
				// credentials: "same-origin",
				// headers: {
				// 	"Content-Type": "application/x-www-form-urlencoded",
				// 	"Cache-Control": "no-cache"
				// },
				body: formData
			})
				.then((res) => res.text())
				.then((data) => {
					if (data) {
						const jobsWrapper =
							document.querySelector(".jobs-wrapper");

						console.log(data);
						jobsWrapper.innerHTML = data;
						jobListPaginationUpdate();
					}
				})
				.catch((err) => console.log(err));

			return false;
		}
	});
}

function jobListPaginationUpdate() {
	const jobListEl = document.querySelector("#jobs-list");
	const paginationLinks = jobListEl.querySelectorAll(".wp-pagenavi > a");

	paginationLinks.forEach((el) => {
		const href = el.href;

		// To do:
		// Fix pagination URL issue

		// console.log(href);
		// href.replace(
		// 	"wp-admin/admin-ajax.php?paged=2",
		// 	"/about/culture/page/2/"
		// );

		// Add anchor
		el.setAttribute("href", href + "#jobs-list");
	});
}

export default { init };
