// Previous/next navigation
export const addPrevNextBtnsClickHandlers = (embla, prevBtn, nextBtn) => {
	const scrollPrev = () => embla.scrollPrev();
	const scrollNext = () => embla.scrollNext();
	prevBtn.addEventListener("click", scrollPrev, false);
	nextBtn.addEventListener("click", scrollNext, false);

	return () => {
		prevBtn.removeEventListener("click", scrollPrev, false);
		nextBtn.removeEventListener("click", scrollNext, false);
	};
};

export const addTogglePrevNextBtnsActive = (embla, prevBtn, nextBtn) => {
	const togglePrevNextBtnsState = () => {
		if (embla.canScrollPrev()) prevBtn.removeAttribute("disabled");
		else prevBtn.setAttribute("disabled", "disabled");

		if (embla.canScrollNext()) nextBtn.removeAttribute("disabled");
		else nextBtn.setAttribute("disabled", "disabled");
	};

	embla
		.on("select", togglePrevNextBtnsState)
		.on("init", togglePrevNextBtnsState)
		.on("reInit", togglePrevNextBtnsState);

	return () => {
		prevBtn.removeAttribute("disabled");
		nextBtn.setAttribute("disabled", "disabled");
	};
};

// Dot navigation
export const addDotBtnsAndClickHandlers = (embla, dots) => {
	let dotNodes = [];

	const addDotBtnsWithClickHandlers = () => {
		dots.innerHTML = embla
			.scrollSnapList()
			.map(
				() =>
					'<button class="embla__dot" type="button"><span class="visually-hidden">Slide dot</span></button>'
			)
			.join("");

		dotNodes = Array.from(dots.querySelectorAll(".embla__dot"));
		dotNodes.forEach((dotNode, index) => {
			dotNode.addEventListener(
				"click",
				() => embla.scrollTo(index),
				false
			);
		});
	};

	const toggleDotBtnsActive = () => {
		const previous = embla.previousScrollSnap();
		const selected = embla.selectedScrollSnap();
		dotNodes[previous].classList.remove("embla__dot--selected");
		dotNodes[selected].classList.add("embla__dot--selected");
	};

	embla
		.on("init", addDotBtnsWithClickHandlers)
		.on("reInit", addDotBtnsWithClickHandlers)
		.on("init", toggleDotBtnsActive)
		.on("reInit", toggleDotBtnsActive)
		.on("select", toggleDotBtnsActive);

	return () => {
		dots.innerHTML = "";
	};
};
