import utils from "../utils";

function init() {
	const teamMemberModalEls = document.querySelectorAll(".team-member-modal");

	const teamMemberModalForm = dialogEventListeners({
		open: document.querySelectorAll(".team-member-modal-link"),
		dialog: document.querySelectorAll(".team-member-modal"),
		close: document.querySelectorAll(".team-member-modal .close")
	});
}

// Open dialog
function openDialog(e) {
	// console.log("Open dialog!");

	const item = e.target.closest(".team-member");
	const dialog = item.querySelector("dialog");

	e.preventDefault();
	dialog.showModal();
}

// Close dialog
function closeDialog(e) {
	// console.log("Close dialog!");

	const item = e.target.closest(".team-member");
	const dialog = item.querySelector("dialog");

	dialog.close();
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open
	args.open.forEach((el) => {
		el.addEventListener("click", (e) => {
			openDialog(e, args.dialog);
		});
	});

	// Close
	// On ::backdrop click
	args.dialog.forEach((el) => {
		el.addEventListener("click", (e) => {
			if (e.target.nodeName === "DIALOG") {
				closeDialog(e);
			}
		});
	});

	// On close button click
	args.close.forEach((el) => {
		el.addEventListener("click", (e) => {
			closeDialog(e);
		});
	});
}

export default { init };
