import utils from "../utils";

function init() {
	const contactModalForm = dialogEventListeners({
		open: document.querySelectorAll(".contact-form-modal-link"),
		dialog: document.querySelector(".contact-modal"),
		close: document.querySelector(".contact-modal .close")
	});
}

// Open dialog
function openDialog(event, dialog) {
	event.preventDefault();
	dialog.showModal();

	// Prevent body scrolling
	// ..

	// Autofocus element
	// ..
}

// Close dialog
function closeDialog(dialog) {
	dialog.close();
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open
	args.open.forEach((el) => {
		el.addEventListener("click", (event) => {
			openDialog(event, args.dialog);
		});
	});

	// Scroll
	window.addEventListener("scroll", () => {
		document.documentElement.style.setProperty(
			"--scroll-y",
			`${window.scrollY}px`
		);
	});

	// Close
	// On ::backdrop click
	args.dialog.addEventListener("click", (event) => {
		if (event.target.nodeName === "DIALOG") {
			closeDialog(args.dialog);
		}
	});
	// On close button click
	args.close.addEventListener("click", (event) => {
		closeDialog(args.dialog);
	});
}

export default { init };
